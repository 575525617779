import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Cookies from 'js-cookie';
import { insertActivityLog } from '../../actions';
import { getAppSource, getUserType } from '../../helpers';

export const CommunicationConsentPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleRemindMe, handleProceed } = props;

  const handleBtnClick = async (type) => {
    Cookies.set("consentPopup", false);
    if (type == 'takeMe') {
      handleActivity('take')
      handleProceed();
    } else {
      handleActivity('remind')
      handleRemindMe(false)
    }
  }

  const handleActivity = async (text) => {
    const data = {
      "accountNumber": Cookies.get('selectedAccount'),
      "activityCode": "UAL210",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  (text == 'take') ? 'FCC Popup - User clicked on Proceed button' : 'FCC Popup - User clicked on Remind me later button',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    await insertActivityLog(data);
  }
 
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} open={true}>
     
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Communication Consent Confirmation</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
             <Typography className={classes.paragraph}>Due to a recent update in FCC (Federal Communication Commission) guidelines, we are seeking your consent for the individual companies to contact you for marketing purposes.</Typography>
            </Box> 

            <Box className={classes.cardContainer}>
             <Typography className={classes.responseShare}>Please update your communication consents now.</Typography>
            </Box> 
          </Box>
        </Box>
    
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={() => handleBtnClick('remindMe')}
            > REMIND ME LATER</Button>
          </Box> 
          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => handleBtnClick('takeMe')}
              >PROCEED</Button>
          </Box> 
        </Box>

        <Box className={classes.noteContainer}>
            <Typography className={classes.note}>(<b>Note:</b> You can always change your communication preference from MyProfile > <span className={classes.communication} onClick={() => handleBtnClick('takeMe')}>Communication Preference </span> section.)</Typography>
        </Box>
       
      </Box> 
    </Dialog>
  )
}
