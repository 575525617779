import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import tick from '../../../assets/PayoffRequest/tick.svg';
import React from 'react';
import { useStyles } from './styles';

export const SubmitConsents = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleGotIt} open={true}>
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{props.name}</Typography>
        </Box>
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
            <Box className={classes.cardContainer}>
               <Typography className={classes.heading}><img src={tick} /><Typography className={classes.changes}>{props.msg}</Typography></Typography>
            </Box>
          </Box>
        </Box>
       
        <Box className={classes.cardFooter}>
          <Button
            variant='contained'
            color='primary'
            className={classes.btn}
            onClick={() => props.handleGotIt()}
            >Got it</Button>
        </Box>
      </Box>
    </Dialog>
  )
}
