import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
  
     pageHeading: {
        color: '#1A4664',
        fontFamily: 'Montserrat',
        fontSize: '22px',
        fontWeight: '700',
        letterSpacing: '0.18px',
        lineHeight: '24px',
        marginBottom: 25,
        marginTop: 24
    },

    infoStrip: {
      width: '100%',
    },

    accordion: {
      backgroundColor: '#F5F5F5',
      border: '1px solid #B4B4B4'
    },
    
    infoBox: {
      backgroundColor: '#7EB1CC',
      margin: '40px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px',
      padding: '15px'
    },
    
    icon: {
        margin: '20px 10px 20px 20px',
        fontSize: '30px',
        color: '#444444',
        height: '23px',
        width: '23px'
      },
    
    line: {
      border: '1px solid #E4E4E4'
    },

    infoText: {
      color: '#474747',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400',
      padding: '5px 20px 20px 16px'
    },
    
    infoHeading: {
      color: '#626262',
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: '700',
      padding: '26px 20px 5px 16px'
    },

    container: {
      width: '120em',
      minWidth: "33.3em",
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },
    
    procedureToFill: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: '700'
    },

    responseSubmit: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: '500'
    },

    otherComment: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 16,
      lineHeight: "22px",
      fontWeight: '600',
      paddingTop: 10
    },

    responseUpdated: {
      color: '#121D22',
      fontFamily: "Montserrat",
      fontSize: 20,
      lineHeight: "22px",
      fontWeight: '600',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontWeight: '500',
        fontSize: 16
      },
    },

    thankYou: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 22,
      lineHeight: "22px",
      fontWeight: '700',
      textAlign: 'center'
    },

    readInst: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: '400'
    },

    instruction: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "32px",
        fontWeight: '400'
    },

    downloadInst: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "32px",
        fontWeight: '600'
    },

    linkStyle: {
        fontFamily: "Montserrat",
        fontSize: '1em',
        color: '#007FFF',
        fontWeight: '600',
        letterSpacing: '0.6px',
        textDecoration: 'underline',
        cursor: "pointer"
      },
    
      supportedDoc: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 18,
        lineHeight: "24px",
        fontWeight: '600'
      },

      supportedDocContainer: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        padding: '12px 20px 12px 38px',
        border:'1px solid #B4B4B4',
        borderRadius : '10px'
      },

      type: {
        color: '#4D4D4D',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: 18,
        minWidth: 164,
        textTransform: 'uppercase'
      },  

      confirmationStatusContainer: {
        border: '1px solid #235A86',
        backgroundColor: "#EFEFEF",
        borderRadius: 4,
        fontSize: 16,
        width: '240px',
        padding: "6px",
        color: '#121D22',
        fontWeight: "600"
      },

      confirmationReceived: {
        border: '1px solid #198100',
        backgroundColor: "#19810033",
        borderRadius: 4,
        fontSize: 14,
        padding: "6px",
        color: '#198100',
        fontWeight: "700",
        marginLeft: 20
      },

      confirmationPending: {
        border: '1px solid #E02020',
        backgroundColor: "#E0202033",
        borderRadius: 4,
        fontSize: 14,
        padding: "6px",
        color: '#E02020',
        fontWeight: "700",
        marginLeft: 20
      },

      disasterDate: {
        color: '#235A86'
      },

      radioLabel: {
        color: "rgba(0,0,0,0.74)",
        fontFamily: "Montserrat",
        fontSize: " 16px",
        fontWeight: "500",
      },
    
      marginFixed: {
        marginTop: 14
      },

      fileUpload:{
        display:'flex',
        alignItems:'center',
        marginTop:'10px',
        marginBottom:'15px',
        [theme.breakpoints.down('sm')]: {
          flexDirection:'column',
          alignItems:'normal',
          clear:'both',
          width: '90%'
        },
      },

      fileButton:{
        borderRadius: '4px',
        border: '1px solid #EDB36A',
        background: '#FFF',
        height: '32px'
      },

      fileBtnDisable:{
        borderRadius: '4px',
        background: '#B4B4B4',
        margin:'0px 15px',
        height: '32px'
      },

      chooseFile:{
        color: '#121D22',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        marginRight:'15px',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '5px'
        },
      },

      uploadedImageContainer:{
        paddingLeft:'20px',
        [theme.breakpoints.down('sm')]: {
          paddingRight:'0px',
        },
      },

      uploadedImage:{
        borderBottom: '3px solid #198100',
        color: '#202020',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '16px',
        paddingBottom: '4px',
        display: 'flex',
      },

      chooseFileButton:{
        borderRadius: '4px',
        border: '1px solid #EDB36A',
        background: '#FFF',
        height: '32px',
        margin: "0px 10px"
      },

      imageText: {
        width: '220px',
        color: '#007FFF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '16px',
        textDecoration: 'underline',
        paddingLeft: 10,
        paddingTop: 2,
        wordWrap: 'break-word',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          width:'235px'
        },
      },

      attachFile: {
        color: '#4D4D4D',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: '600'
      },

      supportedDoc: {
        color: '#4D4D4D',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: '500'
      },

      supportedDocTitle: {
        color: '#202020',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: '600'
      },

      textareaSection: {
        marginTop: 16
      },
      
      textAreaValidation: {
        color: '#202020',
        paddingLeft: '55%',
        [theme.breakpoints.down('sm')]: {
          float: 'left',
          paddingLeft: 0
        },
      },
      
      textArea: {
        color: "#202020",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        width: '60%',
        padding: "10px",
        height: '96px !important',
        border: 'none',
        backgroundColor: 'transparent',
        outline: 'none',
        borderRadius: '4px',
        border: '1px solid #9E9E9E',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
        "&::placeholder": {    
          fontSize: '14px', 
          color: 'rgba(0, 0, 0, 0.54)',
          fontSize: '1rem',
          fontFamily: 'Montserrat',
          fontWeight: 400,
          lineHeight: 1
          }
      },

      saveBtnBox: {
        float: "right"
      },
    
      saveBtn: {
        width: 172,
        fontFamily: "Montserrat",
        fontSize: 16,
        marginLeft: 20,
        marginBottom: 20,
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
        height: '40px',
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          marginTop: 20
        },
      },

      date: {
        color: "#198000",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 700
      },

      finding: {
        fontSize: '18px',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginTop: '40px',
        color: '#000000'
      },

      responseAlreadyReceivedBox: {
        textAlign: 'center',
        marginTop: 120,
        marginBottom: 150
      },

      responseAlreadyReceived: {
        fontSize: '36px',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        marginTop: '14px',
        color: '#1A4664',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            fontSize: 30
        },
      },

      goToHomePage: {
        width: 205,
        fontFamily: "Montserrat",
        fontSize: 16,
        marginTop: 20,
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
        height: '40px',
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          fontSize: 14
        },
      },

      confirmationStatus: {
        display: 'flex'
      },

      errorMsg: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 5,
        fontFamily: 'Montserrat',
        color: "red",
      },

      fileValidate:{
        color: '#121D22',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        marginRight:'15px',
        '&.info':{
          fontSize: '14px',
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '5px'
        },
      },

      transactionText : {
        marginBottom: '8px'
      },

      generalConsent: {
        color: '#4D4D4D',
        fontSize: 14,
        fontWeight: 400
      },

      view : {
        color: "#0071FF",
        fontFamily: "Montserrat",
        fontSize: 10,
        fontWeight: "700",
        textDecoration: 'underline',
        cursor: 'pointer'
      },
      
      summaryText: {
        color: "#202020",
        fontFamily: "Montserrat",
        fontSize: "14px",
        letterSpacing: "0.6px",
        paddingRight: "30px",
        textAlign: 'justify',
        [theme.breakpoints.down('sm')]: {
          paddingRight: "0px",
          paddingBottom: "22px"
        },
      },
      onOffText: {
        color: "#4D4D4D",
        fontFamily: "Montserrat",
        fontSize: 14,
        letterSpacing: "0.6px",
        fontWeight: '500',
        lineHeight: '20px',
      },
      btn: {
        width: "192px",
        height: '40px',
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
      },
      successMsg: {
        fontSize: '1.4em',
        fontWeight: 'bold',
        color: 'green',
        textAlign: 'center',
        marginBottom: 10,
        marginTop: 5,
        fontFamily: 'Montserrat',
      },
    
      btnDisable: {
        width: "192px",
        height: '40px',
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
        backgroundColor: 'lightgrey'
      },

      textRight: {
        [theme.breakpoints.down('sm')]: {
          textAlign: 'right'
        },
      },

      errorMessage: {
        color: 'red',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.36px',
        lineHeight: '19px',
        maxWidth: '80%',
        margin: '0 auto 20px auto'
      },

      note: {
        color: "#121D22",
        fontSize: 14,
        fontWeight: "400",
        fontFamily: "Montserrat",
        wordBreak: "break-word",
        paddingBottom: 10,
        paddingTop: 20
      },

      cardContainer: {
        width: '100%',
        marginTop: 25
      },

      mobileNotAvailable: {
        color: "#121D22",
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "600",
        paddingTop: 4,
        textAlign: 'center'
     },

     goToProfile: {
      color: "#121D22",
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: "400",
        textAlign: 'center',
        marginBottom: 8,
        paddingTop: 4
    },

     infoIcon: {
      width: 32,
      height: 32,
      color: "#F7B15C"
    },

    cardBody: {
      border: '2px solid #EDB36A',
      backgroundColor: "#FBF0E1",
      borderRadius: 6,
      padding: "6px",
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '12px 2em',
      display: 'flex',
      boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      }
    },

    consentAccordian: {
      marginTop: 24,
      marginBottom: 10
    },

    partnerName: {
      color: "#000000",
      fontFamily: "Montserrat",
      fontSize: 15,
      letterSpacing: "0.6px",
      fontWeight: '600',
      lineHeight: '19px'
    },

    text: {
      color: "#4D4D4D",
      fontFamily: "Montserrat",
      fontStyle: 'italic',
      fontSize: 12,
      letterSpacing: "0.6px",
      fontWeight: '400',
      lineHeight: '14px',
      margin: '10px 0px'
    },

    selectAssociatePartner: {
      color: "#121D22",
      fontFamily: "Montserrat",
      fontSize: 16,
      letterSpacing: "0.6px",
      fontWeight: '400',
      lineHeight: '19px',
      margin: '14px 0px'
    },

    mobileAvailable: {
      border: '2px solid #EDB36A',
      backgroundColor: "#FBF0E1",
      borderRadius: 6,
      padding: "6px",
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '12px 2em',
      marginBottom: 25,
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      }
    },

    contactUs: {
      color: '#007FFF',
      cursor: "pointer",
      textDecoration: 'underline',
      fontFamily: "Montserrat",
      fontSize: 14,
    },
    
    contact: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: 14,
      margin: '0 10px'
    },

    loadingRoot: {
      width: '100%',
      height: '100vh',
      padding: '6.4em 6%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ccc',
    },

    badge: {
        fontSize: 12,
        fontWeight: 600,
        marginTop: '2px',
        marginLeft: '10px',
        height: '20px',
        width: '36px' , 
        padding: '1px 5px',
        borderRadius: 20,
        color: 'white'
    }
  }
));


export const RedTooltip = withStyles((theme) => ({
  arrow: {
    color: "#B91717",
  },
  tooltip: {
    backgroundColor: '#B91717',
    color: '#fff',
    fontFamily: "Montserrat",
    boxShadow: '0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
    padding: "0.6em",
    fontSize: 16,
    maxWidth: 600,
  },
}))(Tooltip);

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.main,
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '400px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));