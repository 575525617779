import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { getUserType, getAppSource } from '../../helpers';
import { insertActivityLog, downloadFnmaDocument } from '../../actions';
import downloadIcon from '../../assets/downloadIcon.svg';
import Cookies from 'js-cookie';

export const FnmaBlindBanner = (props) => {

  const { buttonText, buttonURL, bannerImageUrl, bannerBody } = props.banner;
  const classes = useStyles();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errMessage, setErrorMsg] = useState();

  const handleActivity = async () => {
    const data = {
      "accountNumber": Cookies.get("selectedAccount"),
      "activityCode": "UAL211",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": 'FNMA Blind Offer Tile - User clicked on document link',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    await insertActivityLog(data);
  }

  const downloadDocument = async () => {
    setSubmitLoader(true)
    const data = {
      "accountNumber": Cookies.get("selectedAccount"),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await downloadFnmaDocument(data)
    if (response?.status == 200) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'FNMA Blind Solicitation Offer');
      document.body.appendChild(link);
      link.click();
      setSubmitLoader(false)
    } else {
      setSubmitLoader(false)
      setErrorMsg("Unable to download the document at this time. Please try again later or contact us using the details below.")
    }
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.body}>
          <img className={classes.logo} src={bannerImageUrl} alt='fnma tile' />
          <Box>
             <Typography className={classes.records}>Our records indicate that your account is currently past due and you may be eligible for loss mitigation assistance!</Typography>
             <Box className={classes.uploadedImg} onClick={() => {handleActivity();downloadDocument()}}>
               <Typography className={classes.fileName}>{buttonText}</Typography> 
                {!submitLoader && <img className={classes.checkImg} src={downloadIcon} alt='download' style={{cursor: 'pointer'}}/> }
                {submitLoader && <Box><CircularProgress color='primary'/></Box>}
             </Box>
             <Typography className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errMessage !== '' ? errMessage : '' }} />
             <Typography className={classes.contact}>Please contact us to discuss payment resolution options available </Typography>
             <Typography className={classes.call}><a href={"tel:" + buttonURL} style={{color: "#FFE500"}}>{buttonURL}</a></Typography> 
             <Typography className={classes.customerCare}>Our customer care team is available</Typography> 
             <Typography className={classes.timingMonFri} dangerouslySetInnerHTML={{ __html: bannerBody }}></Typography>
            </Box> 
        </Box>
      </Box>
     </>
  )
}
