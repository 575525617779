import React, { useEffect, useState, Fragment } from 'react';
import { Box, CssBaseline, CircularProgress, Grid, Typography, Button } from '@material-ui/core';
import { useStyles, RedTooltip } from './styles';
import { getMyProfile, sendVerificationEmail, resendCode, setEditProfileComponent } from '../../../actions';
import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../../../helpers/encrypt';
import { scrollTop, renderDefaultMsg, getAppSource, getUserType, getLoanLabel } from '../../../helpers';
import {setEditProfileComponentData} from '../../../actions/helperAction'
import { useSelector, useDispatch } from 'react-redux';
import { CommunicationPreferences } from '../CommunicationPreferences';
import {CommunicationPreference} from '../../CommunicationPreference';
import { ChangePassword } from '../ChangePassword';
import { EditProfile } from '../EditProfile';
import { ContactVerification } from '../ContactVerification';
import { EmailVerification } from '../EmailVerification';
import InfoIcon from '@material-ui/icons/Info';
import person from '../../../assets/Profile/person.svg';
import email from '../../../assets/Profile/email.svg';
import home from '../../../assets/Profile/home.svg';
import phone from '../../../assets/Profile/phone.svg';
import notVerify from '../../../assets/Profile/notVerify.svg';
import { Link, useParams } from 'react-router-dom';
import Beenhere from '@material-ui/icons/Beenhere';
import { useHistory } from 'react-router-dom';

const MY_PROFILE = "My Profile"
const COMMUNICATION_PREFERENCES = "Communication Preferences"
const CHANGE_PASSWORD = "Change Password"

export const UserProfile = (props) => {


  const editProfileReducerState = useSelector(state => state.setEditProfileReducer);
  let editProfileReducerDataState = useSelector(state => state.setEditProfileDataReducer);
  const [loader, setLoader] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [phoneSuccessMessage, setPhoneSuccessMessage] = useState('')
  const [userDetail, setUserDetail] = useState({})
  const [selectedTab, setSelectedTab] = useState(MY_PROFILE)
  const [isOpen, setIsOpen] = useState(false)
  const [isVcPopup, setIsVcPopup] = useState(false);
  const [isEvPopup, setIsEvPopup] = useState(false);
  const [verifingPhone, setVerifingPhone] = useState('');
  const [verifingEmail, setVerifingEmail] = useState('');
  const [userUpdated, setUserUpdated] = useState(false)
  const [sendEmailInProcess, setSendEmailInProcess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [isMobileInfoTooltip, setIsMobileInfoTooltip] = useState(false)
  const [isEmailInfoTooltip, setIsEmailInfoTooltip] = useState(false)
  const [resMobileVerify, setResMobileVerify] = useState(false);
  const [resEmailVerify, setResEmailVerify] = useState(false);
  const [viewProfile, setViewProfile] = useState(false)
  const [verifyLoader, setVerifyLoader] = useState({
    phoneLoader: false,
    mailLoader: false
  });
  const [mobileVerify, setMobileVerify] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);

  const [openCPPopup,setOpenCPPopup] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory()
  useEffect(() => {
    // if (props.accountNumber) {
    getUserDetail()
    // }
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (params.id) {
      selcetTab()
    }
  }, [params.id]);

  const selcetTab = () => {
    let urlId = params.id
    if (urlId === 'ChangePassword') {
      setSelectedTab(CHANGE_PASSWORD)
    } else if (urlId === 'CommunicationPreferences') {
      setSelectedTab(COMMUNICATION_PREFERENCES)
    } else if (urlId === 'Profile') {
      setIsOpen(false);
      setSelectedTab(MY_PROFILE);
    }
  }

  const getUserDetail = async () => {
    const requestData = {
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    setLoader(true)
    const result = await getMyProfile(requestData)
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setLoader(false)
        setUserDetail(result);

        setMobileVerify(result.isMobileVerified)
        setEmailVerify(result.isEmailVerified)

        checkEditProfileState();
        checkEditProfileDataState();
      } else {
        setLoader(false)
        setUserDetail({})
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setLoader(false)
      setUserDetail({})
      setErrorMessage(renderDefaultMsg('400'))
    }
    scrollTop();
  }

  const checkEditProfileState = async () => {
    const storeEditProfileReducerState = editProfileReducerState;
    await dispatch(setEditProfileComponent(false));
    setIsOpen(storeEditProfileReducerState);
  }
  const checkEditProfileDataState = async () => {
    const storeEditProfileReducerState = editProfileReducerDataState;
    if(storeEditProfileReducerState.edit){
    await dispatch(setEditProfileComponent(false));
    setIsOpen(storeEditProfileReducerState.edit);
    }
  }

  const nullCheck = (str) => {
    return str ? str : ''
  }
  const userDetailUpdated = () => {
    setIsOpen(false);
    getUserDetail()
    setUserUpdated(true)
  }
  const closeOpenCPPopup=()=>{
    setOpenCPPopup(false);
    editProfileReducerDataState=false;
    setIsOpen(false);
    updateStore();
    getUserDetail()
  }
  useEffect(()=>{
    return(()=>{
      setOpenCPPopup(false);
    })
  },[])

  const mobileOTPVerified = (mobValue) => {
    setResMobileVerify(true);
    setIsVcPopup(false)
    setIsMobileInfoTooltip(false)
    if (viewProfile) {
      getUserDetail()
      if(userDetail.isEmailVerified)
      setOpenCPPopup(true);
    }
  }

  const emailOTPVerified = (emailV) => {
    setResEmailVerify(true);
    setIsEvPopup(false)
    if (viewProfile) {
      getUserDetail()
      if(userDetail.isMobileVerified)
      setOpenCPPopup(true);
    }
  }

  const toggleSetResEmailVerify = () => {
    setResEmailVerify(false);
  }

  const toggleResMobileVerify = () => {
    setResMobileVerify(false);
  }

  const reSendVerificationEmail = async (e) => {
    const data = {
      "firstName": userDetail.firstName,
      "emailID": userDetail.emailID,
      "templeteName": "VerifyEmail",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    setErrorMessage('')
    setSuccessMessage('')
    setSendEmailInProcess(true)

    const result = await sendVerificationEmail(data);

    if (result) {
      setSendEmailInProcess(false)
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setSuccessMessage(lstResponseStatus[0].statusDesc)
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setSendEmailInProcess(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
  }



  const reSendOTP = async (phone, viewProfil) => {
    setResMobileVerify(false);
    setVerifingPhone(phone);
    setVerifyLoader({ ...verifyLoader, phoneLoader: true });
    setViewProfile(viewProfil)
    const data = {
      "otpType": viewProfil ? "verifyfromA360" : "VerifyMobile",
      "mediumType": "SMS",
      "mobileNumber": encrypt(phone),
      "email": "",
      "loanNo": "",
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": userDetail.userName ? userDetail.userName : ''
    }

    setPhoneErrorMessage('')
    setPhoneSuccessMessage('')
    const result = await resendCode(data)
    if (result) {
      setVerifyLoader({ ...verifyLoader, phoneLoader: false });
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setIsVcPopup(true);
      } else {
        setPhoneErrorMessage(lstResponseStatus[0].statusDesc);
      }
    } else {
      setPhoneErrorMessage(renderDefaultMsg('400'))
    }
  }

  // email verification code
  const reSendEmailOTP = async (email, viewProfil) => {
    setVerifingEmail(email);
    setVerifyLoader({ ...verifyLoader, mailLoader: true });
    setViewProfile(viewProfil)
    const data = {
      "otpType": viewProfil ? "verifyfromA360" : "VerifyEmail",
      "mediumType": "Email",
      "mobileNumber": "",
      "email": encrypt(email),
      "loanNo": "",
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": userDetail.userName ? userDetail.userName : ''
    }

    setEmailErrorMessage('')
    const result = await resendCode(data)
    if (result) {
      setVerifyLoader({ ...verifyLoader, mailLoader: false });
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setIsEvPopup(true)
      } else {
        setEmailErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setEmailErrorMessage(renderDefaultMsg('400'))
    }
  }

  const goToEdit = (ED) => {
    setIsOpen(true)
  }
   //If cancel then update store to default
  const updateStore = async()=>{
      await dispatch(setEditProfileComponentData({edit:false,isFromProfilePopup:false}));
  }
  const handleCloseEditProfile=()=>  {
      updateStore();
      editProfileReducerDataState=false;
      setIsOpen(false);
      getUserDetail();
  }

  const renderUpdatedInfo = () => {
    return (<Box display='flex' py={1} px={2} mb={3} className={classes.appBar}>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Box display='flex' alignItems="center">
            <InfoIcon className={classes.infoIcon} />
            <Typography className={classes.infoText}>Changes made to your profile will reflect in 24 hours.</Typography>
          </Box>
          {(!userDetail.isEmailVerified || !userDetail.isMobileVerified) && <Box display='flex' alignItems="center">
            <InfoIcon style={{ fill: '#c90d0d' }} className={classes.infoIcon} />
            {/* <Beenhere style={{ fill: '#c90d0d', fontSize: '18px', }} /> */}
            <Typography className={classes.infoText}>Please verify your {!userDetail?.isEmailVerified && 'email'}{(!userDetail.isEmailVerified && !userDetail.isMobileVerified) && ' and'}{!userDetail.isMobileVerified && ' mobile number'}.</Typography>
          </Box>}
        </Grid>
      </Grid>
    </Box>)
  }
  return (
    <CssBaseline>
      {(userDetail &&  userDetail?.accountNumber) ? (
      <Box container="true" maxWidth='xl' className={classes.root}>
        <Box className={classes.container}>
          <Box className={classes.formContainer}>
            <Box className={classes.tabContainer}>
              <Grid container direction="row" justify="center" alignItems="center" className={classes.tabStyle}>
                <Box p={3}
                  className={`${classes.tabs} ${selectedTab === MY_PROFILE ? classes.activeTab : classes.tabShedow}`}
                  onClick={() => {setSelectedTab(MY_PROFILE);history.push('/UserProfile/Profile')}}>{MY_PROFILE}</Box>
                {(emailVerify && mobileVerify) || true ?
                  <Box
                    p={3}
                    className={`${classes.tabs} ${selectedTab === COMMUNICATION_PREFERENCES ? classes.activeTab : classes.tabShedow}`}
                    onClick={() => {setSelectedTab(COMMUNICATION_PREFERENCES);history.push('/UserProfile/CommunicationPreferences')}}>
                    {COMMUNICATION_PREFERENCES}
                  </Box> :
                  <RedTooltip arrow title={`Please verify your ${!emailVerify == true ? 'email' :' '}${(!emailVerify && !mobileVerify) ? ' and' : ''}${!mobileVerify ? ' mobile number' : ''}.`}><Box
                    p={3}
                    className={`${classes.tabsDisable} ${selectedTab === COMMUNICATION_PREFERENCES ? classes.activeTab : classes.tabShedow}`}
                    >
                    <Link className={classes.linkStyle}>{COMMUNICATION_PREFERENCES}</Link>
                  </Box></RedTooltip>
                }
                <Box
                  p={3}
                  className={`${classes.tabs} ${selectedTab === CHANGE_PASSWORD ? classes.activeTab : classes.tabShedow}`}
                  onClick={() => {setSelectedTab(CHANGE_PASSWORD);history.push('/UserProfile/ChangePassword')}}>
                  {CHANGE_PASSWORD}
                </Box>
              </Grid>
            </Box>

            {errorMessage ? (<Box px={5} py={1}><Typography className="errorMessage">{errorMessage}</Typography></Box>) : null}
            {successMessage ? (<Box px={5} py={1}><Typography className="successMessage">{successMessage}</Typography></Box>) : null}
            {sendEmailInProcess ? (<Box px={5} py={1}><Typography >Email Sending.....</Typography></Box>) : null}

            {selectedTab === MY_PROFILE ? (<Box className={classes.containerPadding}>
              {loader ? (<Box align="center" py={10}><CircularProgress color="primary" /></Box>) : (!isOpen && <Fragment>
                <Box mb={4}>
                  <Box mb={2}>
                    {renderUpdatedInfo()}
                  </Box>
                  <Grid container direction="row" alignItems="center" >
                    <Grid item sm={8} md={4}>
                      <img className={classes.icon} src={person} alt="" />
                      <Typography className={`${classes.labelText} ${classes.labelTextMobile}`}>First Name</Typography>
                      <Typography className={`${classes.labelTextBold} ${classes.labelTextMobile}`}>{nullCheck(userDetail.firstName)}</Typography>
                    </Grid>
                    <Grid item sm={4} md={4}>
                      <Typography style={{ paddingLeft: "45px" }} className={classes.labelText}>Last Name</Typography>
                      <Typography style={{ paddingLeft: "45px" }} className={classes.labelTextBold}>{nullCheck(userDetail.lastName)}</Typography>
                    </Grid>
                    <Grid item md={4}><p className={classes.noteText}><b>Note:</b> For more information, please visit the {getLoanLabel()} Information Page.</p></Grid>
                  </Grid>
                </Box>
                <Box mb={3} pt={1}>
                  <Grid container direction="row" >
                    <Grid item sm={12} md={4} className={classes.profileMargin}>
                      <img className={classes.icon} src={email} alt="" />
                      <Box display="flex">
                        <Typography className={classes.labelText}>Email</Typography>
                      </Box>
                      <Box >
                        <Box className={classes.emailContainer}>{!userDetail.isEmailVerified &&
                          <RedTooltip arrow title={'Please verify your email.'}><img className={classes.errorIcon} src={notVerify} alt="" /></RedTooltip>}</Box>
                        <Typography style={{ paddingLeft: '56px' }} className={`${classes.labelTextname} ${classes.labelTextMobile} ${classes.wordBreak}`}
                        >
                          {userDetail.emailID ? decrypt(userDetail.emailID) : ''}</Typography>
                      </Box>

                      {!userDetail?.isEmailVerified && <Box 
                      style={{ paddingLeft: '56px' }}
                      >
                        {/* <span style={{ position: 'relative', top: '3px', }}><Beenhere style={{ fill: '#c90d0d', fontSize: '13px', }} /></span> */}
                        <button className={classes.verifyText}
                          variant="contained"
                          color="primary"
                          onClick={() => reSendEmailOTP(decrypt(userDetail.emailID), true)}
                        >Verify Now
                        </button>
                        {/* <span className={classes.verifyText} onClick={() => { reSendEmailOTP(decrypt(userDetail.emailID), true) }}>Verify Email</span> */}
                      </Box>}


                    </Grid>
                    <Grid item sm={12} md={4} className={classes.profileMargin}>
                      <img className={classes.icon} src={phone} alt="" />
                      <Box display="flex">
                        <Typography className={`${classes.labelText} ${classes.pl14}`}>Mobile Number</Typography>
                      </Box>
                      <Box>
                        <Box style={{paddingLeft: '25px'}}>{!userDetail.isMobileVerified &&
                          <RedTooltip arrow title={'Please verify your mobile.'}><img className={classes.errorIcon} src={notVerify} alt="" /></RedTooltip>}</Box>

                        <Typography className={` ${classes.labelTextBoldMobile} ${classes.labelTextMobile}`} style={{ paddingLeft: '48px !important' }}>
                          {userDetail?.mobileNo ? decrypt(userDetail.mobileNo) : 'N/A'}</Typography></Box>


                      {!userDetail.isMobileVerified && userDetail?.mobileNo && decrypt(userDetail?.mobileNo) !== '' && <Box style={{ paddingLeft: '49px' }}>
                        {/* <span style={{ position: 'relative', top: '3px', }}><Beenhere style={{ fill: '#c90d0d', fontSize: '13px', }} /></span> */}
                        <button className={classes.verifyText}
                          variant="contained"
                          color="primary"
                          onClick={() => reSendOTP(decrypt(userDetail.mobileNo), true)}
                        >Verify Now
                        </button>
                        {/* <span className={classes.verifyText} onClick={() => { reSendOTP(decrypt(userDetail.mobileNo), true) }}>Verify Mobile</span> */}
                      </Box>}

                    </Grid>
                    <Grid item sm={12} md={4} className={classes.profileMargin}>
                      <img className={classes.icon} src={home} alt="" />
                      <Typography className={classes.labelText}>Mailing Address</Typography>
                      <Typography className={`${classes.labelTextBold} ${classes.pl60} ${classes.wordBreak}`} >
                        {userDetail.address1 ? userDetail.address1 : ''}  {userDetail.address2 ? userDetail.address2 : ''},
                      </Typography>
                      <Typography className={`${classes.pl60} ${classes.labelTextBold} ${classes.wordBreak}`}>
                        {userDetail.city ? userDetail.city : ''}, {userDetail.state ? userDetail.state : ''}  {userDetail.zipCode ? decrypt(userDetail.zipCode) : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box textAlign="center" className={classes.pt0}>
                  <Button className={classes.profileBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => setIsOpen(true)}
                  >EDIT PROFILE
                  </Button>
                </Box>
              </Fragment>)}

              {isOpen === true && <EditProfile
                open={isOpen}
                updateStore={updateStore}
                handleClose={() =>handleCloseEditProfile()}
                reSendOTP={reSendOTP}
                reSendEmailOTP={reSendEmailOTP}
                openCPPopup={openCPPopup}
                closeOpenCPPopup={closeOpenCPPopup}
                user={userDetail}
                accountNumber={props.accountNumber}
                updateDone={() => userDetailUpdated()}
                phoneErrorMessage={phoneErrorMessage}
                emailErrorMessage={emailErrorMessage}
                isMobileVerified={userDetail.isMobileVerified}
                isEmailVerified={userDetail.isEmailVerified}
                resMobileVerify={resMobileVerify}
                resEmailVerify={resEmailVerify}
                toggleSetResEmailVerify={toggleSetResEmailVerify}
                toggleResMobileVerify={toggleResMobileVerify}
                verifyLoader={verifyLoader}
                emailVal={decrypt(userDetail.emailID)}
                contactVal={decrypt(userDetail.mobileNo)}

              />}

              {isVcPopup && <ContactVerification
                updateDone={mobileOTPVerified}
                accountNumber={props.accountNumber}
                open={isVcPopup} handleClose={() => setIsVcPopup(false)}
                mobile={verifingPhone ? verifingPhone : ''}
                userName={userDetail.userName}
                goToEdit={() => {
                  goToEdit()
                }}
                viewProfile={viewProfile}
              ></ContactVerification>}
            </Box>) : null}

            {isEvPopup && <EmailVerification
              goToEdit={() => {
                goToEdit()
              }}
              updateDone={emailOTPVerified}
              accountNumber={props.accountNumber}
              open={isEvPopup}
              handleClose={() => setIsEvPopup(false)}
              email={verifingEmail ? verifingEmail : ''}
              userName={userDetail.userName}
              emailVal={decrypt(userDetail.emailID)}
              contactVal={decrypt(userDetail.mobileNo)}
              viewProfile={viewProfile} />}

            {selectedTab === COMMUNICATION_PREFERENCES ? (<Box className={classes.containerPadding}>
              <CommunicationPreferences accountNumber={props.accountNumber} userDetail={userDetail}></CommunicationPreferences>
            </Box>) : null}

            {selectedTab === CHANGE_PASSWORD ? (<Box className={classes.containerPadding}>
              <ChangePassword accountNumber={props.accountNumber}></ChangePassword>
            </Box>) : null}
          </Box>
        </Box>
      </Box>) :
       (<Box align="center" py={10}><CircularProgress color="primary" /></Box>)
}
{/* { openCPPopup && 
        <CommunicationPreference
          handleClosePopup={closeOpenCPPopup}
          emailID={decrypt(userDetail.emailID)}
          mobileNo={decrypt(userDetail.mobileNo)}
          accountNumber={props.accountNumber}
          updateDone={()=>{}}
          editProfileReducerDataState={editProfileReducerDataState}
          updateStore={updateStore}
          fromSave={false}
        /> } */}
    </CssBaseline>
  )
}
