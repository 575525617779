import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Typography, MenuItem, Select } from '@material-ui/core';
import { useStyles, CutomTextField, SimpleTooltip, RedTooltip } from './styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { updateMyProfile, getStateList, getClientName } from '../../../actions';
import Cookies from 'js-cookie';
import { checkDigitOnly, scrollTop, renderDefaultMsg, checkValidEmail, getAppSource, getUserType } from '../../../helpers';
import { decrypt, encrypt } from '../../../helpers/encrypt';
import CustomSelectState from '../CustomSelectState'
import { isAdmin } from '../../../helpers';
import Beenhere from '@material-ui/icons/Beenhere';
import InfoIcon from '@material-ui/icons/Info';
import Popup from "../../PopUp";
import { insertActivityLog } from '../../../../src/actions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {CommunicationPreference} from '../../CommunicationPreference';
import { useDispatch } from 'react-redux';
import { SubmitConsents } from '../SubmitConsents';

export const EditProfile = (props) => {

  const { open, handleClose, accountNumber, user,
    updateDone, reSendOTP, reSendEmailOTP, isMobileVerified, isEmailVerified } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(props.isMobileVerified);
  const [isMailVerified, setIsMailVerified] = useState(props.isEmailVerified);
  const [resMobileVerify, setResMobileVerify] = useState(props.resMobileVerify);
  const [resEmailVerify, setResEmailVerify] = useState(props.resEmailVerify);
  const [warningPopup, setWarningPopup] = useState({show:false})
  const [openCPPopup,setOpenCPPopup] = useState(false);
  const theme = useSelector(state => state.theme)
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formInput, setFormInput] = useState({
    inputFirstName: '',
    inputLastName: '',
    inputEmail: '',
    inputMobile: '',
    inputAddress1: '',
    inputAddress2: '',
    inputCity: '',
    inputState: '',
    inputZip: ''
  })

  const [formInputError, setFormInputError] = useState({
    inputFirstName: '',
    inputLastName: '',
    inputEmail: '',
    inputMobile: '',
    inputAddress1: '',
    inputAddress2: '',
    inputCity: '',
    inputState: '',
    inputZip: ''
  })
  const [isProcessSave, setIsProcessSave] = useState(false);
  const [saveDisabled,setSaveDisabled] = useState(true);
  const disabledBtn = !(!formInput.inputFirstName.trim() || !formInput.inputLastName.trim() ||
    !formInput.inputAddress1.trim() || !formInput.inputState.trim() || !formInput.inputZip.trim() || !formInput.inputCity.trim());

  const [allStates, setAllStates] = useState([])
  const [CeaseDesistFlag, setCeaseDesistFlag] = useState(false);
  const [dataVerified, setDataVerified] = useState(false);
  const [success, setSuccessStatus] = useState(false);

  useEffect(() => {
    if (allStates.length === 0) {
      getAllStates()
    }
    scrollTop();
  }, [])
  // useEffect(()=>{
  //   setOpenCPPopup(props.openCPPopup)
  // },[props.openCPPopup]);
  
  useEffect(() => {
    if ((Cookies.get('ceaseDesistFlag') !== 'undefined') && (Cookies.get('ceaseDesistFlag') !== undefined)) {
      setCeaseDesistFlag(Cookies.get('ceaseDesistFlag') == ('true' || true) ? true : false);
    } else {
      getCesaeDesistFlag()
    }
  }, [])

  const getCesaeDesistFlag = async () => {

    const data = {
      "accountNumber" : null,
      "userName": Cookies.get('userName'),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, bankDetail } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setCeaseDesistFlag(bankDetail?.ceaseDesistFlag);
      }
    }
  }

  useEffect(() => {
    setResMobileVerify(props.resMobileVerify)
    if (props.resMobileVerify) {
      setIsPhoneVerified(true)
      setSaveDisabled(false)
      setDataVerified(true)
    }
  }, [props.resMobileVerify])

  useEffect(() => {
    setResEmailVerify(props.resEmailVerify);
    if (props.resEmailVerify) {
      setIsMailVerified(true)
      setSaveDisabled(false)
      setDataVerified(true)
    }
  }, [props.resEmailVerify])

  useEffect(() => {
    if (props.user) {
      setErrorMsg('');
      setFormInput({
        inputFirstName: user.firstName,
        inputLastName: user.lastName,
        inputEmail: user.emailID ? decrypt(user.emailID) : '',
        inputMobile: user.mobileNo ? (decrypt(user.mobileNo) === 0 || decrypt(user.mobileNo) === '0') ? '' : decrypt(user.mobileNo) : '',
        inputAddress1: user.address1,
        inputAddress2: user.address2,
        inputCity: user.city,
        inputState: user.state,
        inputZip: user.zipCode ? decrypt(user.zipCode) : ''
      })
    }

  }, [props.user]);

  useEffect(()=> {
    setIsMailVerified(props.isEmailVerified)
    setIsPhoneVerified(props.isMobileVerified)
    
  },[])

  //Check if user changes the field the save chages will enable
  useEffect(()=>{
    if(formInput.inputAddress1===user.address1 && 
      formInput.inputAddress2===user.address2    &&  
      formInput.inputCity===user.city  &&
      formInput.inputEmail===decrypt(user.emailID) &&
      formInput.inputFirstName===user.firstName &&
      formInput.inputLastName===user.lastName &&
      formInput.inputMobile===decrypt(user.mobileNo) &&
      formInput.inputState===user.state &&
      formInput.inputZip===decrypt(user.zipCode)){
        setSaveDisabled(true)
      }
      else{
        setSaveDisabled(false)
      }
  },[formInput,user]);

  const goToContactUs = () => {
    history.push('/contactus');
  }


  const handleChange = (prop) => (event) => {
   
    
    if (prop === 'inputMobile') {
      if (checkDigitOnly(event.target.value)) {
        props.toggleResMobileVerify()
        setIsPhoneVerified(false);
        setResMobileVerify(false);
        setFormInput({ ...formInput, [prop]: event.target.value });
        return;
      }
    } else if (prop === 'inputZip') {
      if ((/^[0-9]+$/).test(event.target.value) || event.target.value === '') {
        setFormInput({ ...formInput, [prop]: event.target.value.slice(0, 5) });
        return;
      }
    } else if (prop === 'inputEmail') {
      props.toggleSetResEmailVerify();
      setIsMailVerified(false);
      setResEmailVerify(false);
      setFormInput({ ...formInput, [prop]: event.target.value });
      return;
    } else {
      setFormInput({ ...formInput, [prop]: event.target.value });
    }
    
  }
  const handleStateChange = (item) => {
    setFormInput({ ...formInput, inputState: item.value });
  }
  const validateForm = () => {
    const formErrors = {
      inputFirstName: '', inputLastName: '', inputEmail: '', inputMobile: '', inputAddress1: '',
      inputAddress2: '', inputCity: '', inputState: '', inputZip: ''
    }
    let errorCount = 0

    setFormInputError(formErrors)
    if (!formInput.inputFirstName) {
      formErrors.inputFirstName = "Please enter First Name";
      errorCount++
    }
    if (!formInput.inputLastName) {
      formErrors.inputLastName = "Please enter Last Name";
      errorCount++
    }
    if (!formInput.inputEmail) {
      formErrors.inputEmail = "Please enter email.";
      errorCount++
    } else if (formInput.inputEmail !== '' && !checkValidEmail(formInput.inputEmail)) {
      errorCount++
      formErrors.inputEmail = "Please enter valid email.";
    } else if (formInput.inputEmail !== '' && !isMailVerified) {
      errorCount++
      formErrors.inputEmail = "Please verify email.";
    }

    if (!formInput.inputAddress1) {
      formErrors.inputAddress1 = "Please enter Address.";
      errorCount++
    }
    if (!formInput.inputCity) {
      formErrors.inputCity = "Please enter City.";
      errorCount++
    }
    if (!formInput.inputZip) {
      formErrors.inputZip = "Please enter Zip.";
      errorCount++
    } else if (formInput.inputZip && formInput.inputZip.length !== 5) {
      formErrors.inputZip = "Please enter valid Zip.";
      errorCount++
    } else if (formInput.inputZip && isNaN(formInput.inputZip) === true) {
      formErrors.inputZip = "Zip code must be numeric.";
      errorCount++
    }

    if (formInput.inputMobile !== '' && formInput.inputMobile.length !== 10) {
      errorCount++
      formErrors.inputMobile = "Please enter valid mobile no.";
    } else if (formErrors.inputMobile !== '' && isNaN(formInput.inputMobile) === true) {
      errorCount++
      formErrors.inputMobile = "Mobile number must be numeric.";
    } else if (formInput.inputMobile !== '' && !isPhoneVerified) {
      errorCount++
      formErrors.inputMobile = "Please enter valid mobile no.";
    }
    setFormInputError(formErrors)
    return errorCount
  }
  const handleOpenPopup=()=>{
    setOpenCPPopup(true);
  }
  const handleClosePopup=()=>{
    updateDone() // close popup  
    setOpenCPPopup(false);
    props.closeOpenCPPopup();
  }
  
  const handleGotIt = () => {
    setSuccessStatus(false)
    handleClosePopup()
    history.push('/UserProfile/CommunicationPreferences');
  }

  const handleSubmit = async () => {
   
    if (validateForm() === 0) {
      const requestData = {
        "accountNumber": props.accountNumber ? props.accountNumber : '',
        "firstName": formInput.inputFirstName,
        "lastName": formInput.inputLastName,
        "emailID": encrypt(formInput.inputEmail),
        "mobileNo": encrypt(formInput.inputMobile),
        "address1": formInput.inputAddress1,
        "address2": formInput.inputAddress2,
        "city": formInput.inputCity,
        "state": formInput.inputState,
        "zipCode": encrypt(formInput.inputZip),
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": ""
      }
      setIsProcessSave(true);
      setErrorMsg('');

      if (formInput.inputFirstName !== props.user?.firstName || formInput.inputLastName !== props.user?.lastName || (formInput.inputEmail) !== decrypt(props.user?.emailID) || (formInput.inputMobile) !== decrypt(props.user?.mobileNo) || formInput.inputAddress1 !== props.user?.address1 || formInput.inputAddress2 !== props.user?.address2 || formInput.inputCity !== props.user?.city || formInput.inputState !== props.user?.state || (formInput.inputZip) !== decrypt(props.user?.zipCode) || dataVerified) {
        
        const result = await updateMyProfile(requestData)
        if (result) {
          const { lstResponseStatus } = result
          if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
            setIsProcessSave(false)
            // handleOpenPopup();  
          //  updateStore();
                
            // scrollTop();
            if ((formInput.inputEmail) !== decrypt(props.user?.emailID) || (formInput.inputMobile) !== decrypt(props.user?.mobileNo)) {
              setSuccessStatus(true)
            } else {
              handleClosePopup()
            }
          } else {
            setIsProcessSave(false)
            setErrorMsg(lstResponseStatus[0].statusDesc)
          }
        } else {
          setIsProcessSave(false)
          setErrorMsg(renderDefaultMsg('400'))
        }
      } else {
          const text = "No API Call- There is no changes in data on Edit My Profile";
          const data = {
            "accountNumber": props.accountNumber ? props.accountNumber : '',
            "activityCode": "UAL055",
            "activityStatus": "Success",
            "action": "UpdateMyProfile",
            "controller": "UpdateMyProfile",
            "apiName": "MyProfile",
            "activityRemark":  text,
            "privateLabelUrl": Cookies.get("privateLabelUrl"),
            "ipAddress": Cookies.get('publicIp'),
            "browser": Cookies.get("browser"),
            "appSource": getAppSource(),
            "userType": getUserType(),
            "userName": "",
          };
          await insertActivityLog(data);
          setIsProcessSave(false)
          updateDone() // close popup          
            scrollTop();
      }
    }
  }

  const handleError = (inputName) => {
    if (formInputError[inputName]) {
      return (<span className={classes.errorText}>{formInputError[inputName]}</span>)
    } else {
      return null
    }
  }
  const closePopupFunc=()=>{
    setWarningPopup({show:false});
  }
  const openPopup=()=>{
    setWarningPopup({show:true});
  }
  const validClass = (inputName) => {
    if (formInputError[inputName]) {
      return classes.inputContainerWithText
    } else {
      return classes.inputContainer
    }
  }

  const getAllStates = async () => {

    setAllStates([])
    setErrorMessage('')
    const result = await getStateList();

    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        let newArray = result.stateList.map(item => {
          return { optionValue: item.stateCode, optionText: item.stateName }
        })
        setAllStates(newArray)
      } else {
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const verifyPhone = (event) => {
    event.preventDefault()
    
    if (formInput.inputMobile !== '') {
      if (formInput.inputMobile.length === 10) {
        if (formInput.inputMobile !== (user.mobileNo ? (user.mobileNo) : '')) {
          setMobileError('');
          setFormInputError({ ...formInputError, ['inputMobile']: '' })
          reSendOTP(formInput.inputMobile, false);
        }

      } else {
        setFormInputError({ ...formInputError, ['inputMobile']: 'Please enter valid mobile no.' })

        // setMobileError('Please verify mobile no.');
      }
    } else {
      setMobileError('');
    }

  }

  const verifyEmail = (event) => {
    event.preventDefault()
    if (checkValidEmail(formInput.inputEmail?.trim())) {
      setEmailError('');
      setFormInputError({ ...formInputError, ['inputEmail']: '' })
      if (formInput.inputEmail !== (user.emailID)) {
        reSendEmailOTP(formInput.inputEmail, false);
      }
    } else {
      // setEmailError('Please enter a valid email.');
      setFormInputError({ ...formInputError, ['inputEmail']: 'Please enter valid email.' })
    }
   
  }

  const handleDisableBtn = () => {
    return (isAdmin() || !disabledBtn || !emailError !== '' || !isPhoneVerified || !isMailVerified)
  }

 

  return (
    <Box className={classes.root}>
      <Box mt={3}>
        <form noValidate autoComplete="off">
          {!errorMessage ? (<Box><Typography className={classes.errorText}>{errorMessage}</Typography></Box>) : null}
          <Box>
            <Grid container spacing={3} justify="space-around" columns={{ xs: 12, sm:6, md: 6 }} className={`${classes.mobileFullWidth}`}>
              <Grid   className={validClass('inputFirstName')} item xs={12} sm={6} md={6} >
                <CutomTextField
                  id="outlined-firstName"
                  label="First Name"
                  variant="outlined"
                  type="text"
                  value={formInput.inputFirstName}
                  onChange={handleChange('inputFirstName')}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                />
                {handleError('inputFirstName')}
              </Grid>
              <Grid className={validClass('inputLastName')} item xs={12} sm={6} md={6} >
                <CutomTextField
                  id="outlined-lastName"
                  label="Last Name"
                  variant="outlined"
                  type="text"
                  value={formInput.inputLastName}
                  onChange={handleChange('inputLastName')}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: 20,
                  }}
                />
                {handleError('inputLastName')}
              </Grid>
              <Grid className={classes.inputContainerWithText} item xs={12} sm={6} md={6} style={{ position: 'relative' }}>
                <CutomTextField
                  id="outlined-email"
                  label="Email address"
                  variant="outlined"
                  type="text"
                  value={formInput.inputEmail}
                  onChange={handleChange('inputEmail')}
                  // onBlur={verifyEmail}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                />
                
                
                {formInputError.inputEmail ? (handleError('inputEmail')) :
                  (<span className={classes.suggestionText}>Editing this field will require you to verify new input</span>)}
                {/* {emailError !== '' && <p className={classes.validationError}>{emailError}</p>} */}

                {formInput.inputEmail !== '' && !((resEmailVerify || (decrypt(user.emailID) === formInput.inputEmail)) && isMailVerified) && <Box style={{ float: 'right', paddingTop: '5px'  }}>
                        {/* <span style={{ position: 'relative', top: '3px', }}><Beenhere style={{ fill: '#c90d0d', fontSize: '13px', }} /></span> */}
                        <button className={classes.verifyText}
                          variant="contained"
                          color="primary"
                          onClick={(event)=>{verifyEmail(event)}}
                        >Verify Now
                        </button>
                        {/* <span className={classes.verifyText} onClick={verifyEmail}>Verify</span> */}
                      </Box>}

                <div position="end" style={{ position: 'absolute', right: '20px', top: '25px' }}>
                  {formInput.inputEmail !== '' && ((resEmailVerify || (decrypt(user.emailID) === formInput.inputEmail)) && isMailVerified ?
                    <SimpleTooltip arrow title={'Verified'}>
                      <CheckCircleIcon style={{ fill: '#44ac44', fontSize: '25px', }} />
                    </SimpleTooltip> :
                    <RedTooltip arrow title={'Not Verified'}>
                      <CancelIcon style={{ fill: '#B91717', fontSize: '25px', cursor: "pointer" }} onClick={verifyEmail} />
                    </RedTooltip>)}
                </div>

                {props.verifyLoader.mailLoader ? <CircularProgress className={classes.verifyLoader} /> : null}
              </Grid>
              <Grid className={classes.inputContainerWithText} item xs={12} sm={6} md={6} style={{ position: 'relative' }}>
                <CutomTextField
                  id="outlined-mobile"
                  label="Mobile Number(Optional)"
                  variant="outlined"
                  type="text"
                  value={formInput.inputMobile}
                  onChange={handleChange('inputMobile')}
                  // onBlur={verifyPhone}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: 10
                  }}
                />
                {formInputError.inputMobile ? (handleError('inputMobile')) :
                  (<span className={classes.suggestionText}>Editing this field will require you to verify new input</span>)}
                {props.phoneErrorMessage && <p className={classes.validationError}>{props.phoneErrorMessage}</p>}
                {mobileError !== '' && <p className={classes.validationError}>{mobileError}</p>}

                {formInput.inputMobile && !(resMobileVerify || (user.mobileNo && ((user.mobileNo ? decrypt(user.mobileNo) : '') === formInput.inputMobile)) && isPhoneVerified) && <Box style={{ float: 'right', paddingTop: '5px' }}>
                        {/* <span style={{ position: 'relative', top: '3px', }}><Beenhere style={{ fill: '#c90d0d', fontSize: '13px', }} /></span> */}
                        <button className={classes.verifyText}
                          variant="contained"
                          color="primary"
                          onClick={(event)=>{verifyPhone(event)}}
                        >Verify Now
                        </button>
                        {/* <span className={classes.verifyText} onClick={verifyPhone}>Verify</span> */}
                      </Box>}

                <div position="end" style={{ position: 'absolute', right: '20px', top: '25px' }}>
                  {formInput.inputMobile && (resMobileVerify || (user.mobileNo && ((user.mobileNo ? decrypt(user.mobileNo) : '') === formInput.inputMobile)) && isPhoneVerified ?
                    <SimpleTooltip arrow title={'Verified'}>
                      <CheckCircleIcon style={{ fill: '#44ac44', fontSize: '25px', }} />
                    </SimpleTooltip> :
                    <RedTooltip arrow title={'Not Verified'}>
                      <CancelIcon style={{ fill: '#B91717', fontSize: '25px', cursor: "pointer" }} onClick={verifyPhone} />
                    </RedTooltip>)}
                </div>

                {props.verifyLoader.phoneLoader ? <CircularProgress className={classes.verifyLoader} /> : null}

              </Grid>
            </Grid>
          
        {CeaseDesistFlag && <Box display='flex' py={1} px={2} mb={3} className={classes.appBar}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.infoIcon} />
                  <Typography className={classes.infoText}>The ability to update below mailing address is unavailable.</Typography>
                  <a class={classes.infoLink} onClick={openPopup}>Read more</a>
                </Box>
              </Grid>
            </Grid>
          </Box>}

            <Grid container spacing={3} justifyContent="space-around" columns={{ xs: 12, sm:6, md: 6 }} className={`${classes.mobileFullWidth}`}>
              <Grid className={validClass('inputAddress1')} item xs={12} sm={6} md={6}>
                <CutomTextField
                  id="outlined-address1"
                  label="Address Line 1"
                  variant="outlined"
                  type="text"
                  value={formInput.inputAddress1}
                  onChange={handleChange('inputAddress1')}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                  disabled={CeaseDesistFlag}
                />
                {handleError('inputAddress1')}
              </Grid>
              <Grid className={validClass('inputAddress2')} item xs={12} sm={6} md={6}>
                <CutomTextField
                  id="outlined-address2"
                  label="Address Line 2"
                  variant="outlined"
                  disabled={CeaseDesistFlag}
                  type="text"
                  value={formInput.inputAddress2}
                  onChange={handleChange('inputAddress2')}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                />
              </Grid>

              <Grid className={validClass('inputCity')} item xs={12} sm={6} md={6}>
                <CutomTextField
                  id="outlined-city"
                  label="City"
                  variant="outlined"
                  disabled={CeaseDesistFlag}
                  type="text"
                  value={formInput.inputCity}
                  onChange={handleChange('inputCity')}
                  fullWidth
                  inputProps={{
                    className: classes.inputStyle,
                  }}
                />
                {handleError('inputCity')}
              </Grid>

              <Grid className={validClass('inputZip')} item xs={12} sm={6} md={6}>
                <Grid container spacing={5} direction="row" justify="center" alignItems="center" >
                  <Grid item xs={12} md={6}>
                    <Box >
                      <CustomSelectState
                        optionsList={allStates}
                        selectedValue={formInput.inputState}
                        disabled={CeaseDesistFlag}
                        dropDownName={'state'}
                        selectCallback={handleStateChange} ></CustomSelectState>
                      {handleError('inputState')}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box >
                      <CutomTextField
                        id="outlined-zip"
                        disabled={CeaseDesistFlag}
                        label="Zip"
                        variant="outlined"
                        type="text"
                        value={formInput.inputZip}
                        onChange={handleChange('inputZip')}
                        fullWidth
                        inputProps={{
                          className: classes.inputStyle,
                          maxLength: 5,
                        }}
                      />
                      {handleError('inputZip')}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '12px' }}>
              {errorMsg !== '' ? (<Box><Typography className={`${classes.errorText} ${classes.errorMsgStyle}`}>{errorMsg}</Typography></Box>) : null}
              <Grid item xs={5} md={6} className={classes.pr35}>
                <Box className={`${classes.cancelBtnBox}`}>
                  <Button
                    onClick={() => handleClose()}
                    className={classes.cancelBtn}
                    variant="outlined"
                  >CANCEL</Button>
                </Box>
              </Grid>
              <Grid item xs={7} md={6} className={classes.pl35}>
                <Box className={`${classes.saveBtnBox}`}>
                  {isProcessSave ? (<Button className={classes.saveBtn} disabled={true} variant="contained"><CircularProgress /></Button>) : (
                    <Button
                     // onClick={() => handleOpenPopup()}
                      onClick={handleSubmit}
                      className={classes.saveBtn}
                      variant="contained"
                      color="primary"
                      disabled={saveDisabled || isAdmin() || !disabledBtn || !(emailError !== '') &&
                        !((isPhoneVerified || !formInput.inputMobile || ((user.mobileNo ? decrypt(user.mobileNo) : '') === formInput.inputMobile)) && (isMailVerified || (decrypt(user.emailID) === formInput.inputEmail)))}
                        >
                      SAVE CHANGES</Button>)}

                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
        {warningPopup?.show && <Popup open={warningPopup?.show} closePopup={closePopupFunc} isWarningPopup={false} isMessagePopup={true}>
        <div className={classes.popupBody}>
           <p className={classes.loanDetail}>
           According to our records, you are represented by an attorney or have otherwise requested that we cease communicating with you directly regarding your account. If you are no longer represented by counsel or wish for us to communicate with you, please send us a request in writing to <span onClick={() => goToContactUs()} className={classes.customerCareEmail}>customer care email</span> with your loan number and a statement that you are not represented by an attorney and wish for us to communicate with you directly.
          </p>
          </div>
          </Popup>}
          {/* { openCPPopup && 
        <CommunicationPreference
        updateStore={props.updateStore}
        handleClosePopup={handleClosePopup}
          emailID={formInput.inputEmail}
          mobileNo={formInput.inputMobile}
          accountNumber={props.accountNumber}
          updateDone={updateDone}
        /> 
      } */}
         {success && <SubmitConsents handleGotIt={handleGotIt} name="Profile Updated" msg="Your profile changes have been successfully saved."/>}

      </Box>
    </Box>
  )
}