import { makeStyles } from '@material-ui/core/styles';
import coveredInsurance from '../../assets/coveredInsurance.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 10,
    marginBottom: 28,
    background: 'linear-gradient(209.9deg, #428DC1 0%, #1363A2 100%)',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    position: 'relative'
  },

  title: {
    color: "#FFF",
    fontSize: 22,
    fontWeight: "700",
    fontFamily: "Montserrat",
    textAlign: 'center',
    maxWidth: '290px',
    wordBreak: "break-word",
    paddingTop: 22
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  logo: {
    marginTop: 10
  },
 
fileName: {
  color: "#FFE500",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "700",
  marginBottom: 4,
  marginTop: 4,
  paddingRight: 10,
  textAlign: 'center',
  textDecoration: 'underline',
  cursor: 'pointer'
},

contact: {
  color: "#FFFFFF",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "500",
  marginBottom: 4,
  marginTop: 4,
  textAlign: 'center'
},

call: {
  color: "#FFE500",
  fontFamily: "Montserrat",
  fontSize: 20,
  fontWeight: "600",
  marginBottom: 4,
  marginTop: 4,
  textAlign: 'center',
  textDecoration: 'underline',
  cursor: 'pointer'
},

customerCare: {
  color: "#FFFFFF",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "600",
  marginTop: 4,
  textAlign: 'center'
},

timingMonFri: {
  color: "#FFE500",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "500",
  marginTop: 2,
  textAlign: 'center',
  marginBottom: 6
},

timingSat: {
  color: "#FFE500",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "500",
  textAlign: 'center',
  marginBottom: 8
},

uploadedImg:{
  color: '#202020',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '16px',
  paddingBottom: '6px',
  marginBottom: 8,
  display: 'flex',
  marginTop: 10,
  justifyContent: 'center'
},

records: {
  color: "#FFFFFF",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "600",
  marginBottom: 4,
  textAlign: 'center',
  marginTop: 6
},

errorMsg: {
  fontSize: 12,
  fontWeight: '600',
  textAlign: 'center',
  marginBottom: 4,
  fontFamily: 'Montserrat',
  color: "#941C2F",
  marginTop: 10
},

}));
