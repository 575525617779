
import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import { useStyles, RedTooltip } from './styles';
import { renderDefaultMsg, getAppSource, getUserType, isAdmin } from '../../../../helpers';
import { savePartnerConsents } from '../../../../actions';
import { decrypt, encrypt } from '../../../../helpers/encrypt';
import { useSelector } from 'react-redux';
import { Switch } from '../Switch';
import { SubmitConsents } from '../../SubmitConsents';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Cookies from 'js-cookie';
import phone from '../../../../assets/call.png';
import sms from '../../../../assets/messages.png';
import newIcon from '../../../../assets/newIcon.svg';
import '../index.css';

export const BsiAssociatedConsents = (props) => {
  const classes = useStyles();
  const theme = useSelector(state => state.theme);
  const { primaryColorCode } = theme;
  const allAccountsDetail = Cookies.get("loanAccounts");
  let allAccounts = JSON.parse(allAccountsDetail);

  const [bsiAssociatedPartners, setBsiAssociatedPartners] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [isBSICPSubmit, setIsBSICPSubmit] = useState(false);
  const [successMsgBSIConsent, setSuccessMsgBSIConsent] = useState(false)
  const [isPartnerConsentExpanded, setPartnerConsentExpanded] = useState(false);
  const [accordionStatus, setAccordionStatus] = useState(true);
  const [success, setSuccessStatus] = useState(false);

  useEffect(() => {
    setBsiAssociatedPartners(props.bsiAssociatedPartners);
  }, [props.bsiAssociatedPartners])

  const handleGotIt = () => {
    props.handleSubmitStatus()
    setSuccessStatus(false)
  }

  const togglePartnerConsent = () => {
    setPartnerConsentExpanded(!isPartnerConsentExpanded);
  };

  const handlePartnerConsentMobile = (consent) => {
    let newObj = [...bsiAssociatedPartners]  
    newObj[consent.index][consent.fieldName] = consent.fieldValue;
    setBsiAssociatedPartners(newObj)
  }

  const handlePartnerConsentText = (consent) => {
    let newObj = [...bsiAssociatedPartners]  
    newObj[consent.index][consent.fieldName] = consent.fieldValue;
    setBsiAssociatedPartners(newObj)
  }

  const handleSaveChanges = async () => {
      setErrorMessage('')
      setIsBSICPSubmit(true)
      setSuccessMsgBSIConsent(false);
    
      var partners = [];
      bsiAssociatedPartners.map((partner) => {
          partners.push({"partnerName": partner.partnerName, "partnerConsent": partner.isPartnerConsentMobile, "partnerConsentType" : 'cell'}, {"partnerName": partner.partnerName, "partnerConsent": partner.isPartnerConsentSMS, "partnerConsentType" : 'sms'})
      })
    
      const loans = allAccounts.map((loan) => encrypt(loan.accountNumber))
   
      const requestData = {
        "accountNumber": loans,
        "contactValueMobile": props.mobileNo,
        "contactValueEmail": props.emailID,
        "partnersList" : partners,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": "",
        "consentSource": "mlwBorrower"
      }

      const response = await savePartnerConsents(requestData);
      if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode == 0) {  
        setSuccessStatus(true)
        setSuccessMsgBSIConsent(true);
        setIsBSICPSubmit(false)
      } else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== 0) {  
        setIsBSICPSubmit(false)
        setSuccessStatus(false)
        setErrorMessage(lstResponseStatus[0].statusDesc)
      } else {
        setIsBSICPSubmit(false)
        setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const learnMore = (partner) => {
    const win = window.open(partner.websiteURL, "_blank");
    win.focus();
  }

  const formatMobileNumber = (e) => {
    let value = e.replace(/\D/g, '');
     if (value.length <= 3) {
          value = value;
        } else if (value.length <= 6) {
          value = value.replace(/(\d{3})(\d{0,3})/, '$1-$2');
        } else {
          value = value.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
        }
     return value;   
  };

  return (
    <CssBaseline>
      <>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container} style={{marginTop: props?.status ? '20px': '0px'}}>
        
          <Box className={classes.infoStrip}>
          {/* {(errorMessage !== '') ? (<p className={classes.errorMsg}> {errorMessage}</p>) : null} */}
  
         <Box className={`${classes.consentAccordian} ${'accordian'}`}>

        <Accordion elevation={0} className={classes.transactionText} expanded={accordionStatus} onChange={() => setAccordionStatus(!accordionStatus)}>
          <AccordionSummary
            expandIcon={ <ExpandMoreIcon />}
            aria-controls={`panelc-content`}
            id={`panelc-header`}
            style={{ backgroundColor: '#F5F5F5',
              border: '1px solid #B4B4B4', borderRadius: '6px 6px 2px 2px'}}
            >
            <Box className={classes.confirmationStatus}>
              <span className={classes.type}>BSI Associated Partners</span> 
              {/* <div className={classes.badge} style={{backgroundColor: primaryColorCode}}>new</div> */}
              <img src={newIcon} alt="icon"/>
            </Box>
          </AccordionSummary>

          <AccordionDetails style={{ border: '1px solid #B4B4B4', borderRadius: '2px 2px 6px 6px'}}>
            
          <Box>
            <Box style={{ width: '100%', paddingTop: 20 }}>
             { (decrypt(props.mobileNo) !== "") && 
             <Box className={classes.mobileAvailable}>
                <Typography className={classes.mobileNotAvailable}>Your registered mobile number in our records is: {formatMobileNumber(decrypt(props.mobileNo))}</Typography>
                <Typography className={classes.goToProfile}>To update your mobile number, go to <a href="./Profile" target="_self" className={classes.contactUs}>My Profile</a> to add or edit your contact information.</Typography>
            </Box> }

            <Typography className={classes.selectAssociatePartner}>Select the partners you’d like to hear from and receive valuable savings opportunities and helpful resources.
 
            </Typography>

            {(bsiAssociatedPartners.length > 0) && bsiAssociatedPartners.map((partners, index) => (
             <Box>
                             
               <Box style={{display: 'flex'}}>
                <Typography className={classes.partnerName}>{partners.partnerNameDisplay}</Typography>
                {/* {partners?.isNew && <Typography className={classes.badge} style={{backgroundColor: primaryColorCode}}>new</Typography>} */}
                {partners?.isNew && <img src={newIcon} alt="icon"/>}
                <Typography className={classes.contact}>Contact - <a href={"tel:" + partners.partnerContactNumber} style={{textDecoration: 'none', cursor: 'pointer', color: '#202020'}}>{partners.partnerContactNumber}</a></Typography>
                <Typography className={classes.contactUs} onClick={() => learnMore(partners)}>Learn More</Typography>
               </Box>
               <Typography className={classes.text}>{partners.textToDisplay}</Typography>

              <Box mb={1}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <Grid item xs={9} sm={9} md={11}> 
                    <Box style={{display: 'flex'}}>
                    <img src={phone} alt="icon" style={{ width: '18px', height: '18px', marginRight: '10px'}}/>

                    <Typography className={classes.onOffText}>
                    {/* <LightToolTip
                      arrow placement="bottom" title={PHONE_TOOLTIP} >
                      <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={phone} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                    </LightToolTip>  */}
                   
                     Receive phone calls </Typography>
                     </Box></Grid>
                    <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                      <Switch
                        index={index}
                        status={partners.isPartnerConsentMobile}
                        handleSwitch={handlePartnerConsentMobile}
                        switchName={"isPartnerConsentMobile"}></Switch>
                    </Grid>
                  </Grid>
                </Box>

                <Box mb={3}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <Grid item xs={9} sm={9} md={11}> 
                    <Box style={{display: 'flex'}}>
                    <img src={sms} alt="icon" style={{ width: '18px', height: '18px',  marginRight: '10px'}}/>

                      <Typography className={classes.onOffText}>
                    
                    {/* <LightToolTip placement="bottom"
                      arrow  title={MESSAGE_TOOLTIP}>
                      <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                    </LightToolTip>  */}
                 
                     Receive text messages </Typography>
                     </Box>
                     </Grid>
                    <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                      <Switch
                        index={index}
                        status={partners.isPartnerConsentSMS}
                        handleSwitch={handlePartnerConsentText}
                        switchName={"isPartnerConsentSMS"}></Switch>
                    </Grid>
                  </Grid>
                </Box>
               </Box> )) } 
             
            </Box>

        <Box className={classes.generalConsent}> {isPartnerConsentExpanded ? <div dangerouslySetInnerHTML={{ __html: props.partnerConsentSummary }}/>  :
          <div dangerouslySetInnerHTML={{ __html:  `${props.partnerConsentSummary?.substring(0, 260)}...` }}></div> }
          <span onClick={togglePartnerConsent} className={classes.view}>{isPartnerConsentExpanded ? 'View Less' : 'View More'}</span>
        </Box>

        <Box textAlign="center" p={3}>
          {errorMessage ? (<Box><Typography className={classes.errorMessage}>{errorMessage}</Typography></Box>) : null}
          {/* {successMsgBSIConsent && <p className={classes.successMsg}>{'Changes saved successfully.'}</p>} */}

          {isBSICPSubmit ? (<Button
            className={classes.btn}
            variant="contained"
            disabled={true}><CircularProgress color="primary" /></Button>) :
              (!(props.isMailVerified && props.isMobileVerified)) ?
                <RedTooltip arrow title={`Please verify your ${!props.isMailVerified ? 'email' : ''}${(!props.isMailVerified && !props.isMobileVerified) ? ' and' : ''}${!props.isMobileVerified ? ' mobile number' : ''}.`}>
                  <Button
                    className={classes.btnDisable} mt={2}
                    variant="contained"
                  >
                    Save Changes</Button>
                    </RedTooltip> :
                <Button
                  onClick={() => handleSaveChanges()}
                  className={classes.btn} mt={2}
                  variant="contained"
                  color="primary"
                  disabled={isAdmin()}
                >
                  Save Changes</Button>
              }
            {(Cookies.get('isMultipleLoan') == 'true') && <Typography className={classes.note}>(<b>Note:</b> Changes made to communication preferences will apply to all loans associated with your MyLoanWeb account.)</Typography> }

            </Box>
           </Box> 
            
          </AccordionDetails>
          </Accordion>
          </Box>
         </Box> 
        </Box>
      </Box>
      {success && <SubmitConsents handleGotIt={handleGotIt} name="BSI Associated Partners" msg="Consent Changes Saved Successfully."/>}
     </> 
    </CssBaseline>
  )
}

