
import React, { useEffect, useState, useRef } from 'react';
import { Box, CssBaseline, Grid, Button, Typography, Tooltip, CircularProgress, ClickAwayListener } from '@material-ui/core';
import { useStyles, RedTooltip } from './styles';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { renderDefaultMsg, scrollTop, getAppSource, getUserType, maskEmail, maskMobile, isAdmin } from '../../../helpers';
import { getCommPreference, updateCommPreference, getContent, partnerConsents, insertActivityLog, savePartnerConsents } from '../../../actions';
import { Switch } from './Switch';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import Cookies from 'js-cookie';
import './index.css';

import { decrypt, encrypt } from '../../../helpers/encrypt';
import { SwitchButton } from '../../SwitchButton'
import email from '../../../assets/emails.png';
import paperless from '../../../assets/CommunicationPreferences/paperless.png';
import phone from '../../../assets/call.png';
import sms from '../../../assets/messages.png';
import { EMAIL_TOOLTIP, PHONE_TOOLTIP, MESSAGE_TOOLTIP, PAPERLESS_TOOLTIP } from '../../../helpers/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { BsiAssociatedConsents } from './BsiAssociatedConsents';
import { SubmitConsents } from '../SubmitConsents';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8
  },
}))(Tooltip);
  

export const CommunicationPreferences = (props) => {
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const componentRef = useRef();

  const privateLabelUrl = Cookies.get("privateLabelUrl")
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const loanInfoData = useSelector(state => state?.loanInfoReducer);

  const [bsiAssociatedPartners, setBsiAssociatedPartners] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [summary, setSummary] = useState('')
  const [partnerConsentSummary, setPartnerConsentSummary] = useState('');
  const [isEmailConsent, setIsEmailConsent] = useState(true)
  const [isCellConsent, setIsCellConsent] = useState(true)
  const [IsSmsConsent, setIsSmsConsent] = useState(true);
  const [cPData, setCPData] = useState({})
  const [isCPSubmit, setIsCPSubmit] = useState(false);
  const [isBSICPSubmit, setIsBSICPSubmit] = useState(false);
  const [isMailVerified, setMailVerify] = useState(false);
  const [isMobileVerified, setMobileVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Result, setResult] = useState({});
  const [successMsg, setSuccessMsg] = useState(false)
  const [successMsgBSIConsent, setSuccessMsgBSIConsent] = useState(false)
  const [openEmailToolTip, setOpenEmailToolTip] = useState(false);
  const [openPhoneToolTip, setOpenPhoneToolTip] = useState(false);
  const [openSmsToolTip, setOpenSmsToolTip] = useState(false);
  const [openLanveraToolTip, setOpenLanveraToolTip] = useState(false);
  const [statementAvailable, setStatementAvailable] = useState(true)
  const [statementRecieve, setStatementRecieve] = useState(true)
  const [isGeneralConsentExpanded, setGeneralConsentExpanded] = useState(false);
  const [isPartnerConsentExpanded, setPartnerConsentExpanded] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [accordionStatus, setAccordionStatus] = useState(true);
  const [success, setSuccessStatus] = useState(false);

  const toggleGeneralConsent = () => {
    setGeneralConsentExpanded(!isGeneralConsentExpanded);
  };

  const togglePartnerConsent = () => {
    setPartnerConsentExpanded(!isPartnerConsentExpanded);
  };

  const handleEmailTooltipClose = () => {
    setOpenEmailToolTip(false);
  };

  const handleEmailTooltipOpen = () => {
    setOpenEmailToolTip(true);
  };
 
  const handlePhoneTooltipClose = () => {
    setOpenPhoneToolTip(false);
  };

  const handlePhoneTooltipOpen = () => {
    setOpenPhoneToolTip(true);
  };

  const handleSmsTooltipClose = () => {
    setOpenSmsToolTip(false);
  };

  const handleSmsTooltipOpen = () => {
    setOpenSmsToolTip(true);
  };

  const handleLanveraTooltipClose = () => {
    setOpenLanveraToolTip(false);
  };

  const handleLanveraTooltipOpen = () => {
    setOpenLanveraToolTip(true);
  };

   useEffect(() => {
    getContentData();
  }, [])

  useEffect(() => {
    // if (props.accountNumber) {
      Cookies.remove('redirectUrl')
      getCP()
      // getPartnerConsents()
    // }
  }, [props.accountNumber]);

  const getPartnerConsents = async () => {
    const response = await partnerConsents(props.accountNumber)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode == 0) {  
       setErrorMessage('')
       setSubmitStatus(response?.responseData.responseData.isSubmit)
       setBsiAssociatedPartners(response?.responseData.responseData.partnersList)
    }  else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== 0) {
      setErrorMsg(response.lstResponseStatus[0].statusDesc)
    } else {
      setErrorMessage(renderDefaultMsg('400'));
   }
  }


  const getContentData = async () => {
    const res = await dispatch(getContent('PC004'));
    if (res) {
      let cpSummary = res.filter(item => item.contentKey == 'CommunicationConsent')
      setSummary(cpSummary[0].contentValue)
      if (privateLabelUrl?.toUpperCase() == 'BSI') {
        let cpSummary = res.filter(item => item.contentKey == 'PartnerConsent')
        setPartnerConsentSummary(cpSummary[0].contentValue)
      }
    }
  }

  const getCP = async () => {
    const requestData = {
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    
    setLoading(true)
    setErrorMessage('')
    let result = await getCommPreference(requestData)
    setResult(result)

    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode == 0)) {
        setCPData(result)
        setIsEmailConsent(result.isEmailConsent)
        setIsCellConsent(result.isCellConsent)
        setIsSmsConsent(result.isSmsConsent)
        setStatementAvailable(result.isLanveraESmtNotification)
        setStatementRecieve(result.isLanveraEStatements)
        setMailVerify(result.isEmailVerified)
        setMobileVerify(result.isMobileVerified)
        setLoading(false)
      } else {
        setLoading(false)
        setCPData({})
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setLoading(false)
      setCPData({})
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
  
  const handleSubmit = async () => {
    setErrorMessage('')
    const requestData = {
      "accountNumber": props.accountNumber,
      "isLanveraEStatements": statementRecieve,
      "isLanveraESmtNotification": isEmailConsent,
      "isCellConsent": isCellConsent,
      "isSmsConsent": IsSmsConsent,
      "isEmailConsent": isEmailConsent,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    if (Result.isEmailConsent !== isEmailConsent || Result.isCellConsent !== isCellConsent || Result.isSmsConsent !== IsSmsConsent || Result.isLanveraEStatements !== statementRecieve) {
      setIsCPSubmit(true)
      setErrorMessage('')
      setSuccessMsg(false);
    const result = await updateCommPreference(requestData);
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setSuccessStatus(true)
        getCP()
        // setSuccessMsg(true);
        setIsCPSubmit(false)
      } else {
        setIsCPSubmit(false)
        setErrorMessage(lstResponseStatus[0].statusDesc)
      }
    } else {
      setIsCPSubmit(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
  } else {
    setIsCPSubmit(true)
    const text = "No API Call- There is no changes in data on Communication preferences";
    const data = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "activityCode": "UAL057",
      "activityStatus": "Success",
      "action": "UpdateCommPreference",
      "controller": "UpdateCommPreference",
      "apiName": "MyProfile",
      "activityRemark":  text,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    await insertActivityLog(data);
    // setTimeout(() => {
      setIsCPSubmit(false)
      setSuccessMsg(true);
    // }, 1000)
  }
}

  const handleSwitchCallback = (item) => {
    setSuccessMsg(false);
    if (item.fieldName === 'isEmailConsent') {
      setIsEmailConsent(item.fieldValue)
    } else if (item.fieldName === 'isCellConsent') {
      setIsCellConsent(item.fieldValue)
    } else if (item.fieldName === 'IsSmsConsent') {
      setIsSmsConsent(item.fieldValue)
    } else if (item.fieldName === 'statementRecieve') {
      setStatementRecieve(item.fieldValue)
    } else if (item.fieldName === 'statementAvailable') {
      setStatementAvailable(item.fieldValue)
    }
  }

  const handleSubmitStatus = () => {
    setSubmitStatus(true)
  }

  const handleGotIt = () => {
    setSuccessStatus(false)
  }

  if (loading) {
    return (
      <Box className={classes.loadingRoot}>
        <CircularProgress />
      </Box>
    )
  } 
  
  return (
    <CssBaseline>
      <>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
        
          <Box className={classes.infoStrip} ref={componentRef}>
          <Grid container direction="row" alignItems="center" >
            <Grid item xs={8} sm={8} md={10}> 
            </Grid>
            <Grid item xs={4} sm={4} md={2}> 
              <ReactToPrint
                trigger={() => <Box className={classes.printBtnIcon} >
                  Print Form
                <PrintIcon className={classes.printIconStyle} />
                </Box>}
                content={() => componentRef.current}
              />
            
            </Grid>
          </Grid>
          {/* {(errorMessage !== '') ? (<p className={classes.errorMsg}> {errorMessage}</p>) : null} */}
  
          <Box>
            
          { ((loanInfoData && loanInfoData?.purgeLoanInfo?.liquidationStatus)) &&
              <Box className={classes.infoBoxConatiner}>
                <Box display='flex' alignItems="center">
                  <InfoIcon className={classes.infoIconContainer} />
                  {(loanInfoData?.purgeLoanInfo?.liquidationType?.toLowerCase() == 'payoff') &&
                    <Typography className={classes.infoTextContainer}>Your loan has been paid in full.</Typography>
                  }
                  { (loanInfoData?.purgeLoanInfo?.liquidationType?.toLowerCase() == 'servicetransfer') &&
                    <Typography className={classes.infoTextContainer}>Your loan has been Transferred to New Servicer.</Typography>
                  }
                  { (loanInfoData?.purgeLoanInfo?.liquidationType?.toLowerCase() == 'chargeoff') &&
                    <Typography className={classes.infoTextContainer}>Your loan has been charged off.</Typography>
                  }
                  { (loanInfoData?.purgeLoanInfo?.liquidationType?.toLowerCase() == 'fclcompleted') &&
                    <Typography className={classes.infoTextContainer}>A foreclosure sale date has been held with respect to this property.</Typography>
                  }
                 
                </Box>
              </Box>
            }
    
     
        {(cPData.lstResponseStatus?.length > 0)  && 
           <>
           {(cPData && (decrypt(cPData?.mobileNo) == "") && ((loanInfoData && !loanInfoData?.purgeLoanInfo?.liquidationStatus))) && <Box className={classes.cardBody}>
             <InfoIcon className={classes.infoIcon} /> 
             <Box style={{paddingLeft: 10}}>
               <Typography className={classes.mobileNotAvailable}>We do not have your mobile number updated in our records.</Typography>
               <Typography className={classes.goToProfile}>To update your mobile number, go to <a href="./Profile" target="_self" className={classes.contactUs}>My Profile</a> and add your contact information.</Typography>
             </Box>
            </Box> }
            </> }
         
         {(cPData.lstResponseStatus?.length > 0) &&
           <>
            {(!submitStatus && (decrypt(cPData?.mobileNo) !== "") && (privateLabelUrl?.toUpperCase() == '') && ((loanInfoData && !loanInfoData?.purgeLoanInfo?.liquidationStatus))) && <Box className={classes.actionRequired}>
             <InfoIcon className={classes.infoIcon} /> 
             <Typography className={classes.action}>Action Required:</Typography> 
             <Box style={{paddingLeft: 10}}>
               <Typography className={classes.pending}>Your confirmation for communication consent with <b>BSI Associated Partners</b> is still pending. 
               </Typography>
               <Typography className={classes.goToProfile}>Please <b>review</b> your default consents below and click <b>'SAVE CHANGES'</b> button to confirm.</Typography>
             </Box>
            </Box> }
            </> }
         
            {(cPData.lstResponseStatus?.length > 0) &&
            <>
            {cPData && cPData.mobileNo && bsiAssociatedPartners && !submitStatus && (decrypt(cPData?.mobileNo) !== "") && (privateLabelUrl?.toUpperCase() == '') && <BsiAssociatedConsents accountNumber={props.accountNumber} userDetail={props.userDetail} isMailVerified={isMailVerified} isMobileVerified={isMobileVerified} partnerConsentSummary={partnerConsentSummary} bsiAssociatedPartners={bsiAssociatedPartners} submitStatus={submitStatus} handleSubmitStatus={handleSubmitStatus} mobileNo={cPData.mobileNo ? cPData?.mobileNo : ''} emailID={cPData?.emailID} status={false}/>}
            </> }

          <Box className={`${classes.consentAccordian} ${'accordian'}`}>

            <Accordion elevation={0} className={classes.transactionText} expanded={accordionStatus} onChange={() => setAccordionStatus(!accordionStatus)}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon />}
                aria-controls={`panelc-content`}
                id={`panelc-header`}
                style={{ backgroundColor: '#F5F5F5',
                  border: '1px solid #B4B4B4', borderRadius: '6px 6px 2px 2px'}}
                >
                <Box className={classes.confirmationStatus}>
                  <span className={classes.type}>Communication Preferences</span> 
                </Box>
              </AccordionSummary>

              <AccordionDetails style={{ border: '1px solid #B4B4B4', borderRadius: '2px 2px 6px 6px'}}>
                 
              <Box>
                <Box style={{ width: '100%', paddingTop: 20 }}>
                  <Box mb={3} style={{ width: '100%' }}>
                    <Grid container direction="row" alignItems="center" >
                      <Grid item xs={9} sm={9} md={11}> 
                      <Typography className={classes.onOffText}>
                       
                    {!fullScreen && <LightToolTip
                      arrow placement="bottom" title={EMAIL_TOOLTIP} >
                      <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={email} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                    </LightToolTip> }
                    {fullScreen && 
                    <ClickAwayListener onClickAway={handleEmailTooltipClose}>
                    <LightToolTip
                        arrow placement="bottom" title={EMAIL_TOOLTIP}
                        open={ openEmailToolTip }
                      >
                          <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handleEmailTooltipOpen}><img src={email} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                      </LightToolTip>
                    </ClickAwayListener>}
                      Receive email on {cPData.emailID ? maskEmail(decrypt(cPData.emailID)) : ''}</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                        <SwitchButton

                          status={isEmailConsent}
                          handleSwitch={handleSwitchCallback}
                          switchName={"isEmailConsent"}></SwitchButton>
                      </Grid>
                    </Grid>
                  </Box>
                  {cPData.mobileNo ?
                    (<Box mb={3}>
                      <Grid container direction="row" justify="space-between" alignItems="center" >
                        <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                        
                        {!fullScreen && <LightToolTip
                          arrow placement="bottom" title={PHONE_TOOLTIP} >
                          <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={phone} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                        </LightToolTip> }
                        {fullScreen && 
                        <ClickAwayListener onClickAway={handlePhoneTooltipClose}>
                        <LightToolTip
                            arrow placement="bottom" title={PHONE_TOOLTIP}
                            open={ openPhoneToolTip }
                          >
                              <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handlePhoneTooltipOpen}><img src={phone} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                          </LightToolTip>
                        </ClickAwayListener>}
                        Receive phone calls on {cPData.mobileNo ? maskMobile(decrypt(cPData.mobileNo)) : ''}</Typography></Grid>
                        <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                          <SwitchButton
                            status={isCellConsent}
                            handleSwitch={handleSwitchCallback}
                            switchName={"isCellConsent"}></SwitchButton>
                        </Grid>
                      </Grid>
                    </Box>) : null}
                  {cPData.mobileNo ?
                    (<Box mb={3}>
                      <Grid container direction="row" justify="space-between" alignItems="center" >
                        <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                        
                        {!fullScreen && <LightToolTip placement="bottom"
                          arrow  title={MESSAGE_TOOLTIP} >
                          <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                        </LightToolTip> }
                        {fullScreen && 
                        <ClickAwayListener onClickAway={handleSmsTooltipClose}>
                        <LightToolTip placement="bottom"
                            arrow title={MESSAGE_TOOLTIP}
                            open={ openSmsToolTip }
                          >
                              <span style={{ position: 'relative', top: '7px', left: '-8px', cursor: 'pointer' }}  onClick={handleSmsTooltipOpen}><img src={sms} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                          </LightToolTip>
                        </ClickAwayListener>} 
                          Receive text messages on  {cPData.mobileNo ? maskMobile(decrypt(cPData.mobileNo)) : ''}</Typography></Grid>
                        <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                          <SwitchButton
                            status={IsSmsConsent}
                            handleSwitch={handleSwitchCallback}
                            switchName={"IsSmsConsent"}></SwitchButton>
                        </Grid>
                      </Grid>
                    </Box>) : null}
                  <Box mb={3}>
                    <Grid container direction="row" justify="space-between" alignItems="center" >
                      <Grid item xs={9} sm={9} md={11}> <Typography className={classes.onOffText}>
                     
                       
                        {!fullScreen && <LightToolTip placement="bottom"
                          arrow title={PAPERLESS_TOOLTIP} >
                          <span style={{ position: 'relative', top: '11px', left: '-8px' , paddingRight: '2px', cursor: 'pointer' }}><img src={paperless} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                        </LightToolTip> }
                        {fullScreen && 
                        <ClickAwayListener onClickAway={handleLanveraTooltipClose}>
                        <LightToolTip placement="bottom"
                            arrow title={PAPERLESS_TOOLTIP}
                            open={ openLanveraToolTip }
                          >
                          <span style={{ position: 'relative', top: '11px', left: '-8px' , paddingRight: '2px', cursor: 'pointer' }}  onClick={handleLanveraTooltipOpen}><img src={paperless} alt="check icon" style={{ width: '20px', height: '23px', }}/></span>
                      </LightToolTip>
                    </ClickAwayListener>}
                      Go paperless and receive statements electronically</Typography></Grid>
                      <Grid item xs={3} sm={3} md={1} className={classes.textRight}>
                        <SwitchButton
                          status={statementRecieve}
                          handleSwitch={handleSwitchCallback}
                          switchName={"statementRecieve"}></SwitchButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
            
            <Box className={classes.generalConsent}> {isGeneralConsentExpanded ? <div dangerouslySetInnerHTML={{ __html: summary }}/>  :
              <div dangerouslySetInnerHTML={{ __html:  `${summary?.substring(0, 260)}...` }}></div> }
              <span onClick={toggleGeneralConsent} className={classes.view}>{isGeneralConsentExpanded ? 'View Less' : 'View More'}</span>
            </Box>

            <Box textAlign="center" p={3}>
              {errorMessage ? (<Box><Typography className={classes.errorMessage}>{errorMessage}</Typography></Box>) : null}
              {successMsg && <p className={classes.successMsg}>{'Changes saved successfully.'}</p>}

              {isCPSubmit ? (<Button
                className={classes.btn}
                variant="contained"
                disabled={true}><CircularProgress color="primary" /></Button>) :
                  (!(isMailVerified && isMobileVerified)) ?
                    <RedTooltip arrow title={`Please verify your ${!isMailVerified ? 'email' : ''}${(!isMailVerified && !isMobileVerified) ? ' and' : ''}${!isMobileVerified ? ' mobile number' : ''}.`}>
                      <Button
                        className={classes.btnDisable} mt={2}
                        variant="contained"
                      >
                        Save Changes</Button>
                        </RedTooltip> :
                    <Button
                      onClick={() => handleSubmit() }
                      className={classes.btn} mt={2}
                      variant="contained"
                      color="primary"
                      disabled={isAdmin()}
                    >
                      Save Changes</Button>
                  }
            {/* {(Cookies.get('isMultipleLoan') == 'true') && <Typography className={classes.note}>(<b>Note:</b> Changes made to communication preferences will apply to all loans associated with your MyLoanWeb account.)</Typography> } */}

            </Box>

              </Box> 
                 
             </AccordionDetails>
            </Accordion>
          </Box>
          
          {(cPData.lstResponseStatus?.length > 0) &&
           <>
           {cPData && cPData.mobileNo && bsiAssociatedPartners && submitStatus && (decrypt(cPData?.mobileNo) !== "") && (privateLabelUrl?.toUpperCase() == '') && <BsiAssociatedConsents accountNumber={props.accountNumber} userDetail={props.userDetail} isMailVerified={isMailVerified} isMobileVerified={isMobileVerified} partnerConsentSummary={partnerConsentSummary} bsiAssociatedPartners={bsiAssociatedPartners} submitStatus={submitStatus} handleSubmitStatus={handleSubmitStatus} mobileNo={cPData.mobileNo ? cPData?.mobileNo : ''} emailID={cPData?.emailID} status={true}/>}
           </> }

           {/* <Grid container direction="row" alignItems="center" style={{marginTop: '20px'}}>
            <Grid item xs={8} sm={8} md={10}> 
            </Grid>
            <Grid item xs={4} sm={4} md={2}> 
              <ReactToPrint
                trigger={() => <Box className={classes.printBtnIcon} >
                  Print Form
                <PrintIcon className={classes.printIconStyle} />
                </Box>}
                content={() => componentRef.current}
              />
            
            </Grid>
          </Grid> */}
          

          </Box>
         </Box> 
        </Box>
      </Box>
      {success && <SubmitConsents handleGotIt={handleGotIt} name="Communication Preferences" msg="Consent Changes Saved Successfully."/>}

     </> 
    </CssBaseline>
  )
}

