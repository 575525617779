import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  heading:{
    color: '#198100',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16
    },
  },
  responseReceived:{
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize:  18,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#121D22',
    marginTop:'20px'
  },
  body:{
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop:'20px'
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: 22,
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  linkStyle: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.2em',
    fontWeight: "600",
    marginTop: 5,
  },

  btn: {
    width: 175,
    height: '40px',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    margin: "10px",
    padding: '10px 10px',
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: '14px'
    }
  },

  cardFooter: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: "#fff",
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 80px 0px',
      width: '94%'
    },
  },

  boxMargin: {
    marginLeft: 75
  },

  paragraph: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: 15,
    fontWeight: "500",
    marginBottom: 8,
    textAlign: 'center'
 },

cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginTop: 15,
},


cardIcon: {
  width: 28,
  height: 22,
  color: "#EDB36A",
  marginTop: 22,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},

paragraph: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "500",
  marginBottom: 8,
  textAlign: 'center',
},

changes: {
  marginLeft: 8,
  color: '#198100',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 16
  },
}

}));

