import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 550,
    borderRadius: 12,
    // backgroundColor: 'white',
    background: 'linear-gradient(209.9deg, #428DC1 0%, #1363A2 100%)',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBodyContainer: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  btn: {
    width: 175,
    height: '40px',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    margin: "10px",
    padding: '10px 10px',
    // color: '#202020',
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: '14px'
    }
  },

  closeIcon: {
    position: 'absolute',
    right: 42,
    paddingTop: 18,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },
  closeIco: {
    position: 'absolute',
    right: -5,
    paddingTop: 7,
    [theme.breakpoints.down('sm')]: {
        right: 10
    }
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  
  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    textAlign: 'center',
    // backgroundColor: "#fff",
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 80px 0px',
      width: '94%'
    },
  },

cardContainer: {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginTop: 15,
},

cardContent: {
  display: 'flex'
},

cardTitle: {
color: "#000000",
fontFamily: "Montserrat",
fontSize: "12px",
fontWeight: "600",
marginBottom: '20px',
letterSpacing: '0.15px',
linHheight: '24px',
},

checkImg: {
    width: 22,
    height: 26,
    [theme.breakpoints.down('sm')]: {
      paddingTop: "6px",
      width: 23
    },
},

imageContainer: {
    paddingTop: "6px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
},

request: {
    color: "#198100",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.31px",
    lineHeight: "15px",
    fontWeight: "600",
    paddingTop: 4,
    paddingLeft: 4,
    [theme.breakpoints.down('sm')]: {
        lineHeight: "20px"
    },
},

fileName: {
  color: "#FFE500",
  fontFamily: "Montserrat",
  fontSize: 18,
  fontWeight: "700",
  marginBottom: 4,
  marginTop: 4,
  textAlign: 'center',
  textDecoration: 'underline',
  paddingRight: 10,
  cursor: 'pointer'
},

contact: {
  color: "#FFFFFF",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "500",
  marginBottom: 4,
  marginTop: 4,
  textAlign: 'center'
},

customerCare: {
  color: "#FFFFFF",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "500",
  marginBottom: 2,
  marginTop: 10,
  textAlign: 'center'
},

timing: {
  color: "#FFE500",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "500",
  marginTop: 2,
  textAlign: 'center'
},

uploadedImg:{
  color: '#202020',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '16px',
  paddingBottom: '6px',
  marginBottom: 8,
  display: 'flex',
  marginTop: 10
},

records: {
  color: "#FFFFFF",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "600",
  marginBottom: 4,
  marginTop: 4,
  textAlign: 'center'
},

errorMsg: {
  fontSize: 16,
  fontWeight: '600',
  textAlign: 'center',
  marginBottom: 4,
  fontFamily: 'Montserrat',
  color: "#941C2F",
  marginTop: 10
},

}));

