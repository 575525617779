import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { decrypt } from '../../helpers/encrypt';
import { insertActivityLog, downloadFnmaDocument } from '../../../src/actions';
import { getAppSource, getUserType } from '../../helpers';
import downloadIcon from '../../assets/downloadIcon.svg';
import Cookies from 'js-cookie';

export const FemaBlindPopup = (props) => {
 
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { accountNumber, data } = props;

  const [submitLoader, setSubmitLoader] = useState(false);
  const [errMessage, setErrorMsg] = useState();

  const downloadDocument = async () => {
    setSubmitLoader(true)
    const data = {
      "accountNumber": accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await downloadFnmaDocument(data)
    
    if (response?.status == 200) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'FNMA Blind Solicitation Offer');
      document.body.appendChild(link);
      link.click();
      setSubmitLoader(false)
    } else {
      setSubmitLoader(false)
      setErrorMsg("Unable to download the document at this time. Please try again later or contact us using the details below.")
    }
  }

  const handleOkBtnClick = async () => {
    handleActivity('ok')
    const allAccountsDetail = await Cookies.get("loanAccounts");
    let allAccounts = JSON.parse(allAccountsDetail);
    const index = allAccounts.findIndex((item) => item.accountNumber == decrypt(props?.accountNumber));
    const newData = [...allAccounts];
    newData[index].fnmaBlindPopup = false;
    Cookies.set("loanAccounts", newData);
    props.handleCancelIcon()
  }

  const handleActivity = async (text) => {
    const data = {
      "accountNumber": accountNumber ? accountNumber : '',
      "activityCode": "UAL211",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  (text == 'download') ? 'FNMA Blind Offer Popup - User clicked on document link' : 'FNMA Blind Offer Popup  - User clicked on OK button',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    await insertActivityLog(data);
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} onClose={props.handleCancelIcon} open={true}>
      <Box className={classes.root}>
        
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Your Attention is Required !!!</Typography>
        </Box>
        
        <Box className={classes.cardBodyContainer}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
             <Typography className={classes.records}>Our records indicate that your account is currently past due and you may be eligible for loss mitigation assistance!</Typography>
             <Box className={classes.uploadedImg} onClick={() => {handleActivity('download');downloadDocument()}}>
               <Typography className={classes.fileName}>{data[0]?.buttonText}</Typography> 
               {!submitLoader && <img className={classes.checkImg} src={downloadIcon} alt='download' style={{cursor: 'pointer'}}/> }
               {submitLoader && <Box><CircularProgress color='primary'/></Box>}
             </Box>
             <Typography className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errMessage !== '' ? errMessage : '' }} />
             <Typography className={classes.contact}>Please contact us to discuss payment resolution options available <a href={"tel:" + data[0]?.buttonURL} style={{textDecoration: 'underline', cursor: 'pointer', color: '#FFE500', fontWeight: '600'}}>{data[0]?.buttonURL}</a></Typography> 
             <Typography className={classes.customerCare}>Our customer care team is available</Typography> 
             <Typography className={classes.timing} dangerouslySetInnerHTML={{ __html: data[0]?.taxBannerBody}}></Typography> 
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
          <Button
            variant='contained'
            color='primary'
            className={classes.btn}
            onClick={() => handleOkBtnClick()}
            >OK</Button>
        </Box>
      </Box> 
    </Dialog>
  )
}
