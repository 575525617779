import React from 'react';
import './style.css'

export const Switch = (props) => {
const {status, handleSwitch, switchName, index} = props

const handleChange=()=>{
    if(handleSwitch){
        handleSwitch({fieldName:switchName, fieldValue:!status, index})
    }
}

  return (<label className="switchContainer">
    <input type="checkbox" onChange={()=>handleChange()} checked={props.status} />
    <span className={`sliders round title`}>{props.switchONText?props.switchONText: 'ON'} <span className="offSwitch">{props.switchOFFText?props.switchOFFText:'OFF'}</span></span>    
  </label>)
}